import { PostPublic, PostType } from 'pn-backend';

import { OK, Telegram, Twitter, VK, WhatsApp } from '~assets';
import { config } from '~services';
import { getPostSummary } from '~utils';

export const getSharesUrl = (
  shareType: string,
  post: PostPublic<PostType, string>,
  shareInfo?: { imageURL: string; result: string },
) => {
  const { postCover, postLink, postTitle } = getPostSummary(post);
  const fullLink = `${config.ORIGIN_URL}${postLink}`;

  const text = shareInfo ? shareInfo.result : postTitle;
  const image = shareInfo ? shareInfo.imageURL : postCover;

  switch (shareType) {
    case 'vk':
      return `https://vk.com/share.php?url=${fullLink}&title=${text}&image=${image}`;

    case 'odnoklassniki':
      return `https://connect.ok.ru/offer?url=${fullLink}&title=${text}&imageUrl=${image}`;

    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(`${text} — ${fullLink}`)}`;

    case 'telegram':
      return `https://t.me/share/url?url=${fullLink}&text=${text}`;

    case 'twitter':
      return `http://twitter.com/share?url=${fullLink}&text=${text}`;

    default:
      return '';
  }
};

export const sharesIcons = [
  {
    icon: VK,
    type: 'vk',
  },
  {
    icon: OK,
    type: 'odnoklassniki',
  },
  {
    icon: WhatsApp,
    type: 'whatsapp',
  },
  {
    icon: Telegram,
    type: 'telegram',
  },
  {
    icon: Twitter,
    type: 'twitter',
  },
];
