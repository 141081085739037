// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockArticleBillboard: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.createScroll({
        containerId: 'adfox_174075269438055071',
        ownerId: 1479532,
        params: {
          p2: 'imax',
          pp: 'i',
          ps: 'gqer',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Тип баннера: billboard
  // Расположение: низ страницы

  return (
    <Grid $background="white" $maxWidth="1290px">
      <div id="adfox_174075269438055071" />
    </Grid>
  );
};
