// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockArticleBillboardTopMob: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_17407530078445071',
        ownerId: 1479532,
        params: {
          p2: 'hius',
          pp: 'g',
          ps: 'gqer',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Тип баннера: 300x300
  // Расположение: верх страницы-

  return (
    <Grid $margin="30px">
      <div id="adfox_17407530078445071" />
    </Grid>
  );
};
