// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockFullscreenMob: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_174075284866395071',
        ownerId: 1479532,
        params: {
          p2: 'jkdg',
          pp: 'g',
          ps: 'gqer',
        },
        type: 'fullscreen',
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / Главная / Сайдбар - 300х600
  // Расположение: верх страницы
  // Тип баннера: Fullscreen mobile

  return (
    <Grid>
      <div id="adfox_174075284866395071" />
    </Grid>
  );
};
