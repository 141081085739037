import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockFloorDesk: FC = () => {
  const initBanner = () => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_174075274601985071',
        onClose: () => {
          setTimeout(() => window.Ya?.adfoxCode.reload('adfox_174075274601985071'), 30000);
        },
        ownerId: 1479532,
        params: {
          p2: 'jkdi',
          pp: 'i',
          ps: 'gqer',
        },
        platform: 'desktop',
        type: 'floorAd',
      });
    });
  };

  useLayoutEffect(() => {
    initBanner();
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Тип баннера: FloorAd desktop
  // Расположение: низ страницы

  return (
    <Grid
      $position="fixed"
      $bottom={0}
      $margin="0 auto"
      $height="100px"
      $background="red"
      $width="100%"
      $zIndex="100000000"
    >
      <div id="adfox_174075274601985071" />
    </Grid>
  );
};
