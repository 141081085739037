// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockArticleBillboardMob: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.createScroll({
        containerId: 'adfox_174075304251645071',
        ownerId: 1479532,
        params: {
          p2: 'hius',
          pp: 'i',
          ps: 'gqer',
        },
      });
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Тип баннера: 300x300
  // Расположение: низ страницы

  return (
    <Grid>
      <div id="adfox_174075304251645071" />
    </Grid>
  );
};
