// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';

export const AdvBlockFloorMob: FC = () => {
  const initBanner = () => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_174075279065585071',
        onClose: () => {
          setTimeout(() => window.Ya?.adfoxCode.reload('adfox_174075279065585071'), 30000);
        },
        ownerId: 1479532,
        params: {
          p2: 'jkdh',
          pp: 'i',
          ps: 'gqer',
        },
        type: 'floorAd',
      });
    });
  };

  useLayoutEffect(() => {
    initBanner();
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Тип баннера: 320x100
  // Расположение: низ страницы

  return (
    <Grid
      $position="fixed"
      $bottom={0}
      $margin="0 auto"
      $height="180px"
      $background="red"
      $width="100%"
      $zIndex="100000000"
    >
      <div id="adfox_174075279065585071" />
    </Grid>
  );
};
