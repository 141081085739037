import { BlockType } from 'pn-backend';
import React, { FC, useContext, useEffect, useState } from 'react';

import { Grid, Image, Text } from '~atoms';
import { NextButton, Tooltips } from '~components';
import { TestContext } from '~providers';
import { BlockProps } from '~types';
import { isDefined } from '~utils';

import { ImageAnswer } from './components/ImageAnswer';
import { ImageAnswerOverflow } from './components/ImageAnswerOverflow';
import { TextAnswerOption } from './components/TextAnswerOption';

export const TestQuestionBlock: FC<BlockProps<BlockType.TestQuestion>> = ({ compiled }) => {
  const {
    answerOptions,
    answerType,
    coverCaption,
    coverURL,
    hideRightAnswer,
    question,
    rightAnswerCaption,
    wrongAnswerCaption,
  } = compiled;

  const {
    moveNextStep,
    setAnswer,
    state: { currentQuestion, isLastQuestion, totalQuestions },
  } = useContext(TestContext);

  const [selectedAnswer, setSelectedAnswer] = useState<null | number>(null);
  const isSelected = isDefined(selectedAnswer);
  const isRightAnswerSelected =
    selectedAnswer !== null && selectedAnswer > -1 && answerOptions[selectedAnswer]?.isRightAnswer;

  const imageAnswerCaption = isRightAnswerSelected ? rightAnswerCaption : wrongAnswerCaption;

  useEffect(() => {
    setSelectedAnswer(null);
  }, [currentQuestion]);

  const renderAnswerOptions = () => {
    switch (answerType) {
      case 'text':
        return (
          <Grid
            $padding="0 30px 30px"
            $lg={{
              $maxWidth: '100%',
              $padding: '0',
            }}
          >
            {answerOptions.map((answerOption, index) => (
              <span key={index}>
                <TextAnswerOption
                  key={index}
                  index={index}
                  rightAnswerCaption={rightAnswerCaption}
                  wrongAnswerCaption={wrongAnswerCaption}
                  isSelected={isSelected}
                  selectedAnswer={selectedAnswer}
                  hideRightAnswer={hideRightAnswer}
                  setAnswer={setAnswer}
                  setSelectedAnswer={setSelectedAnswer}
                  {...answerOption}
                />
              </span>
            ))}
          </Grid>
        );
      case 'image':
        return (
          <>
            <Grid
              key="ImageAnswer"
              $padding="0 30px 30px"
              $gridTemplateColumns="1fr 1fr"
              $gap="9px"
              $width="inherit"
              $lg={{ $maxWidth: '100%', $padding: '0' }}
              $sm={{ $gap: '15px', $gridTemplateColumns: '1fr', $padding: '0 12px' }}
            >
              {answerOptions.map((answerOption, i) => {
                return (
                  <ImageAnswer imageURL={answerOption.imageURL} text={answerOption.text} key={i} />
                );
              })}
            </Grid>
            <Grid
              $padding="0 30px 30px"
              $gridTemplateColumns="1fr 1fr"
              $gap="9px"
              $position="absolute"
              $background="transparent"
              $transform="translateZ(1px)"
              $width="inherit"
              $lg={{ $maxWidth: '100%', $padding: '0' }}
              $sm={{ $gap: '15px', $gridTemplateColumns: '1fr', $padding: '0 12px' }}
            >
              {answerOptions.map((answerOption, index) => {
                return (
                  <ImageAnswerOverflow
                    key={index}
                    index={index}
                    isSelected={isSelected}
                    selectedAnswer={selectedAnswer}
                    hideRightAnswer={hideRightAnswer}
                    setAnswer={setAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    {...answerOption}
                  />
                );
              })}
            </Grid>

            {isSelected && imageAnswerCaption && (
              <Grid $padding="24px 30px 40px" $sm={{ $padding: '30px 15px 10px' }}>
                <Tooltips html={imageAnswerCaption} />
                <Text $weight={400} $size={18} $lineHeight={23} $textWithLink>
                  <span dangerouslySetInnerHTML={{ __html: imageAnswerCaption }} />
                </Text>
              </Grid>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Grid $gap="30px" $sm={{ $gap: '20px' }}>
      {coverURL ? (
        <Grid
          $position="relative"
          $height="400px"
          $imageObjectFit="cover"
          $sm={{ $height: '186px', $margin: '0 15px' }}
        >
          <Image src={coverURL} alt={coverCaption} />
          {coverCaption && (
            <Grid
              $margin="10px auto 0"
              $width="auto"
              $position="absolute"
              $bottom="5px"
              $right="10px"
              $left="10px"
            >
              <Text
                $weight={400}
                $size={12}
                $lineHeight={14}
                $textWithLink
                $color="gray-light"
                $textAlign="end"
              >
                <span dangerouslySetInnerHTML={{ __html: coverCaption }} />
              </Text>
            </Grid>
          )}
        </Grid>
      ) : null}
      <Grid $background="white" $sm={{ $margin: '0 15px' }}>
        <Grid
          $padding="30px 30px 20px"
          $lg={{
            $margin: '0 15px',
            $maxWidth: '100%',
            $padding: '20px 15px 0',
            $width: 'calc(100% - 30px)',
          }}
          $sm={{ $padding: '20px 0 0' }}
        >
          {`${currentQuestion + 1}/${totalQuestions}`}
        </Grid>
        <Grid
          $padding="0 30px 20px"
          $lg={{
            $margin: '0 15px',
            $maxWidth: '100%',
            $padding: '20px 15px 0',
            $width: 'calc(100% - 30px)',
          }}
          $sm={{ $padding: '10px 0 0' }}
        >
          <Tooltips html={question} />
          <Text
            $weight={500}
            $size={24}
            $lineHeight={36}
            $color="black"
            $textWithLink
            $sm={{ $lineHeight: 30, $size: 20 }}
          >
            <span dangerouslySetInnerHTML={{ __html: question }} />
          </Text>
        </Grid>
        <Grid
          $margin="0 0 60px"
          $width="100%"
          $position="relative"
          $lg={{ $margin: '30px 15px', $width: 'calc(100% - 30px)' }}
          $sm={{ $margin: '15px 0 30px', $width: '100%' }}
        >
          {renderAnswerOptions()}
        </Grid>
        <Grid
          $overflow="hidden"
          $maxHeight={isSelected ? '81px' : '0'}
          $transition={isSelected ? 'max-height 0.2s ease' : 'unset'}
        >
          <NextButton onClick={moveNextStep}>{isLastQuestion ? 'Результаты' : 'Далее'}</NextButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
