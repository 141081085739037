import { BlockContentIntroCompiled, BlockType, PostType } from 'pn-backend';
import { inc } from 'ramda';
import React, { FC, useEffect, useRef, useState } from 'react';

import { Grid, Text } from '~atoms';
import { articleBlockMap } from '~blockMaps';
import { ShareButton, SMI2 } from '~components';
import { useLayout } from '~hooks';
import {
  AdvBlock,
  AdvBlock2,
  AdvBlockArticleBillboard,
  AdvBlockArticleBillboardMob,
  AdvBlockArticleBillboardTopMob,
  AdvBlockArticleInline,
  AdvBlockArticleInlineMob,
  AdvBlockFullscreenMob,
} from '~mocks';
import { ReadMore } from '~readMore';
import { PostProps } from '~types';
import { blockTypeEquals, getPostSummary, isDefined, renderPostBlock } from '~utils';

import { useMobile } from '../../../hooks/useMobile';
import { ArticleCoverBlock } from './ArticleCoverBlock';

export const Article: FC<
  PostProps<PostType.Article | PostType.NewsFull> & {
    isPShapedBannerVisible: boolean;
  }
> = ({ isPShapedBannerVisible, nextPosts: readMorePosts, post }) => {
  const {
    authors,
    compiled: { blocks },
  } = post;
  const [author] = authors;
  const { isAuthorVisible } = getPostSummary(post);

  const coverBlockIndex = inc(blocks.findIndex(blockTypeEquals(BlockType.Cover)));

  const coverBlocks = blocks.slice(0, coverBlockIndex);
  const restBlocks = blocks.slice(coverBlockIndex);

  const isMobile = useMobile();
  const { layout } = useLayout();

  const isNews = layout === PostType.NewsFull;
  const contentRef = useRef<HTMLDivElement>(null);

  const [isAdv2Visible, setIsAdv2Visible] = useState(false);

  useEffect(() => {
    if (contentRef?.current?.clientHeight && contentRef?.current?.clientHeight > 1300) {
      setIsAdv2Visible(true);
    }
  }, [contentRef]);

  // Для пустой вводки - другая логика отображения рекламы - после первого блока текста
  const isFirstBlockEmpty =
    restBlocks[0].type === BlockType.Intro &&
    !(restBlocks[0].compiled as BlockContentIntroCompiled)?.html;

  return (
    <Grid $position="relative" $sm={{ $marginBottom: '40px' }}>
      <Grid
        $display="flex"
        $width={isPShapedBannerVisible ? '1000px' : '1200px'}
        $margin="0 auto"
        ref={contentRef}
        $xl={{ $width: '100%' }}
      >
        <Grid $maxWidth="750px" $margin="0 auto">
          <ArticleCoverBlock blocks={coverBlocks} />
          <Grid>
            {typeof isMobile !== 'undefined' && isMobile && <AdvBlockArticleBillboardTopMob />}
          </Grid>
          <Grid $position="relative">
            {restBlocks.map((block, i) => {
              let deskAds = !isMobile && (i + 2) % 3 === 0;

              if (isFirstBlockEmpty) {
                deskAds = !isMobile && i !== 0 && (i + 1) % 3 === 0;
              }

              const mobAds = isMobile && i !== 0 && i % 3 === 0;
              const withAdvBlock = deskAds || mobAds;

              if (withAdvBlock) {
                return (
                  <>
                    {typeof isMobile !== 'undefined' &&
                      (isMobile ? (
                        <AdvBlockArticleInlineMob index={i} />
                      ) : (
                        <AdvBlockArticleInline index={i} />
                      ))}
                    {renderPostBlock(articleBlockMap)(block, i)}
                  </>
                );
              }
              return renderPostBlock(articleBlockMap)(block, i);
            })}
          </Grid>

          <Grid
            $maxWidth="750px"
            $width="100%"
            $margin="70px auto 0"
            $display="flex"
            $justifyContent="space-between"
            $alignItems="center"
            $sm={{ $margin: '50px auto 0', $padding: '0 15px' }}
          >
            {isDefined(author) && isAuthorVisible && (
              <Text
                $weight={600}
                $size={20}
                $lineHeight={22}
                $sm={{
                  $lineHeight: 20,
                  $size: 18,
                }}
              >
                {author.name}
              </Text>
            )}
            <ShareButton />
          </Grid>
        </Grid>
        <Grid
          $gap="10px"
          $display="flex"
          $flexDirection="column"
          $position="relative"
          $height="inherit"
          id="advBlockWrapper"
        >
          {!isPShapedBannerVisible && (
            <Grid $height="600px" $position="relative" $xl={{ $display: 'none' }}>
              {typeof isMobile !== 'undefined' && !isMobile && <AdvBlock />}
            </Grid>
          )}
          {!isPShapedBannerVisible && isAdv2Visible && (
            <Grid id="container" $height="100%" $xl={{ $display: 'none' }}>
              {typeof isMobile !== 'undefined' && !isMobile && <AdvBlock2 />}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid $margin="30px auto 0">
        {typeof isMobile !== 'undefined' && !isMobile && <AdvBlockArticleBillboard />}
        {typeof isMobile !== 'undefined' && isMobile && <AdvBlockArticleBillboardMob />}
      </Grid>
      <Grid
        $padding="0 0 30px"
        $background="white"
        $width="100vw"
        $position="relative"
        $left="50%"
        $right="50%"
        $margin="0 -50vw 0"
      >
        <ReadMore posts={readMorePosts} />
      </Grid>
      {!isNews && (
        <Grid
          $background="#F0F0F0"
          $width="100vw"
          $position="relative"
          $left="50%"
          $right="50%"
          $margin="0 -50vw 60px"
          $sm={{
            $margin: '0 -50vw 20px',
          }}
        >
          <SMI2 />
        </Grid>
      )}
    </Grid>
  );
};
