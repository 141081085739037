import Link from 'next/link';
import { not } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Grid, Text } from '~atoms';
import { GridPostCardProps } from '~types';
import {
  formatTime,
  getPostPreviewSummary,
  isDefined,
  isEmptyString,
  removeParagraph,
} from '~utils';

interface Props extends GridPostCardProps {
  isOnMain?: boolean;
}

export const NewsItem: FC<Props> = ({ isOnMain = false, post }) => {
  const { postLink, publishedAt, source, sourceUrl, title } = getPostPreviewSummary(post);

  const [currentPublishedAt, setCurrentPublishedAt] = useState<Nullable<string>>(null);

  useEffect(() => {
    setCurrentPublishedAt(publishedAt);
  }, [publishedAt]);

  return (
    <Grid $gap="10px" $transition="all 1s ease-in-out">
      <Grid
        $gridAutoFlow="column"
        $gap="10px"
        $display="flex"
        $alignItems="center"
        $sm={{ $display: 'flex', $gap: '5px', $gridAutoColumns: 'unset' }}
      >
        {isDefined(currentPublishedAt) && not(isEmptyString(currentPublishedAt)) && (
          <Text $size={22} $lineHeight={24} $weight={300} $lg={{ $lineHeight: 18, $size: 16 }}>
            {formatTime(currentPublishedAt)}
          </Text>
        )}
        {source && (
          <Text $display="inline-flex" $alignItems="center">
            &#8226;
          </Text>
        )}
        {source && (
          <Text
            $weight={300}
            $size={22}
            $lineHeight={26}
            $whiteSpace="nowrap"
            $lg={{ $lineHeight: 20, $size: 16 }}
            $sm={{ $whiteSpace: 'unset' }}
          >
            {source}
          </Text>
        )}
      </Grid>
      <Grid $display="inline-block">
        {title && (
          <Link href={postLink} passHref>
            <Text
              $weight={500}
              $size={26}
              $lineHeight={31}
              $padding="4px 0"
              $display="inline"
              $cursor="pointer"
              $newsItemTitle={isOnMain ? 'main' : 'tab'}
              $textWithLink
              $lg={{ $lineHeight: 24, $size: 20 }}
              $sm={{ $lineHeight: 22, $size: 18 }}
            >
              <span dangerouslySetInnerHTML={{ __html: removeParagraph(title) }} />
            </Text>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};
