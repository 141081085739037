import { PostPreviewPublic } from 'pn-backend';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { ThreeSmall } from '~threeSmall';
import { GridLayoutProps } from '~types';

interface Props {
  posts: PostPreviewPublic[];
}

export const ReadMore: FC<Props> = ({ posts }) => {
  // ToDo Написать собственный компонент, в основе должны лежать картинки medium качества
  const cards = { posts } as GridLayoutProps;

  return (
    <Grid
      id="read_more_block"
      $width="100%"
      $background="white"
      $padding="30px 0 0"
      $xl={{ $margin: '30px auto 0', $padding: '0' }}
      $sm={{ $margin: '30px auto 0', $padding: '0' }}
    >
      <Grid $maxWidth="1200px" $margin="0 auto" $width="100%" $xl={{ $padding: '0 15px' }}>
        <Text
          $textTransform="lowercase"
          $weight={600}
          $size={28}
          $lineHeight={39}
          $sm={{ $lineHeight: 36, $size: 26 }}
        >
          Читайте также
        </Text>
      </Grid>

      <Grid $margin="30px 0 0" $sm={{ $margin: '20px 0 0' }}>
        <ThreeSmall {...cards} />
      </Grid>
    </Grid>
  );
};
