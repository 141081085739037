import {
  BlockType,
  BlockTypeContentMap,
  ColumnsTextPosition,
  CoverFormat,
  GalleryType,
  ImageFormat,
  ImageProxyGravity,
  ImageProxyResize,
  IncutType,
  LineType,
  ListType,
  MoreOnTopicType,
  PreviewImageFormat,
  QuoteType,
  TestAnswerType,
  VideoBlockType,
  VideoFormat,
  WidgetFormat,
} from '../types';
import { EMPTY_RAW_DRAFT_CONTENT_STATE } from '../util/draft/consts';

// ToDo Исправить null -> number кастинг
export const emptyBlockContentFactoryMap: {
  [T in BlockType]: () => BlockTypeContentMap[T];
} = {
  card(): BlockTypeContentMap[BlockType.Card] {
    return {
      blockIds: [],
    };
  },
  columns(): BlockTypeContentMap[BlockType.Columns] {
    return {
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
      images: [],
      textPosition: ColumnsTextPosition.Left,
      title: '',
    };
  },
  cover(): BlockTypeContentMap[BlockType.Cover] {
    return {
      caption: '',
      fileId: null as unknown as number,
      format: CoverFormat.CaptionAbove,
      hideInPost: false,
    };
  },
  description(): BlockTypeContentMap[BlockType.Description] {
    return {
      text: '',
    };
  },
  gallery(): BlockTypeContentMap[BlockType.Gallery] {
    return {
      images: [
        {
          caption: '',
          fileId: null as unknown as number,
          height: 0,
          width: 0,
        },
      ],
      type: GalleryType.Slider,
    };
  },
  gif(): BlockTypeContentMap[BlockType.Gif] {
    return {
      caption: '',
      fileId: null as unknown as number,
    };
  },
  h1(): BlockTypeContentMap[BlockType.H1] {
    return {
      text: '',
    };
  },
  h2(): BlockTypeContentMap[BlockType.H2] {
    return {
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
    };
  },
  h3(): BlockTypeContentMap[BlockType.H3] {
    return {
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
    };
  },
  image(): BlockTypeContentMap[BlockType.Image] {
    return {
      caption: '',
      fileId: null as unknown as number,
      format: ImageFormat.ContentWidth,
    };
  },
  incut(): BlockTypeContentMap[BlockType.Incut] {
    return {
      caption: '',
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
      items: [''],
      listType: ListType.Numbered,
      type: IncutType.Text,
    };
  },
  intro(): BlockTypeContentMap[BlockType.Intro] {
    return {
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
    };
  },
  line(): BlockTypeContentMap[BlockType.Line] {
    return {
      type: LineType.Dots,
    };
  },
  list(): BlockTypeContentMap[BlockType.List] {
    return {
      options: [
        {
          content: EMPTY_RAW_DRAFT_CONTENT_STATE,
        },
      ],
      type: ListType.Numbered,
    };
  },
  meta(): BlockTypeContentMap[BlockType.Meta] {
    return {
      canonical: '',
    };
  },
  moreOnTopic(): BlockTypeContentMap[BlockType.MoreOnTopic] {
    return {
      fileId: null as unknown as number,
      items: [
        {
          caption: '',
          link: '',
        },
      ],
      type: MoreOnTopicType.Text,
    };
  },
  newsSource(): BlockTypeContentMap[BlockType.NewsSource] {
    return {
      name: '',
      url: '',
    };
  },
  preview(): BlockTypeContentMap[BlockType.Preview] {
    return {
      fileId: null as unknown as number,
      images: {
        [PreviewImageFormat.Big]: {
          // TODO: Указать правильные размеры
          gravity: ImageProxyGravity.CE,
          height: 240,
          resize: ImageProxyResize.Auto,
          width: 320,
        },
        [PreviewImageFormat.Medium]: {
          gravity: ImageProxyGravity.CE,
          height: 240,
          resize: ImageProxyResize.Auto,
          width: 320,
        },
        [PreviewImageFormat.Mobile]: {
          gravity: ImageProxyGravity.CE,
          height: 240,
          resize: ImageProxyResize.Auto,
          width: 320,
        },
        [PreviewImageFormat.Small]: {
          gravity: ImageProxyGravity.CE,
          height: 240,
          resize: ImageProxyResize.Auto,
          width: 320,
        },
        [PreviewImageFormat.Wide]: {
          gravity: ImageProxyGravity.CE,
          height: 240,
          resize: ImageProxyResize.Auto,
          width: 320,
        },
      },
    };
  },
  quiz(): BlockTypeContentMap[BlockType.Quiz] {
    return {
      answerOptions: [''],
      caption: '',
      fileId: null as unknown as number,
      question: '',
    };
  },
  quote(): BlockTypeContentMap[BlockType.Quote] {
    return {
      caption: '',
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
      expertName: '',
      fileId: null as unknown as number,
      type: QuoteType.Expert,
    };
  },
  seoTitle(): BlockTypeContentMap[BlockType.SeoTitle] {
    return {
      text: '',
    };
  },
  specLink(): BlockTypeContentMap[BlockType.SpecLink] {
    return {
      url: '',
    };
  },
  subtitle(): BlockTypeContentMap[BlockType.Subtitle] {
    return {
      text: '',
    };
  },
  test(): BlockTypeContentMap[BlockType.Test] {
    return {
      blockIds: [],
    };
  },
  testQuestion(): BlockTypeContentMap[BlockType.TestQuestion] {
    return {
      answerOptions: [
        {
          fileId: null as unknown as number,
          isRightAnswer: true,
          text: EMPTY_RAW_DRAFT_CONTENT_STATE,
        },
      ],
      answerType: TestAnswerType.Text,
      coverCaption: EMPTY_RAW_DRAFT_CONTENT_STATE,
      fileId: null as unknown as number,
      hideRightAnswer: false,
      question: EMPTY_RAW_DRAFT_CONTENT_STATE,
      rightAnswerCaption: EMPTY_RAW_DRAFT_CONTENT_STATE,
      wrongAnswerCaption: EMPTY_RAW_DRAFT_CONTENT_STATE,
    };
  },
  testResults(): BlockTypeContentMap[BlockType.TestResults] {
    const createResult = () => ({
      caption: EMPTY_RAW_DRAFT_CONTENT_STATE,
      fileId: null as unknown as number,
      text: EMPTY_RAW_DRAFT_CONTENT_STATE,
      title: EMPTY_RAW_DRAFT_CONTENT_STATE,
      valuesFrom: null as unknown as number,
      valuesTo: null as unknown as number,
    });

    return {
      average: createResult(),
      maximum: createResult(),
      minimum: createResult(),
    };
  },
  text(): BlockTypeContentMap[BlockType.Text] {
    return {
      content: EMPTY_RAW_DRAFT_CONTENT_STATE,
    };
  },
  video(): BlockTypeContentMap[BlockType.Video] {
    return {
      caption: '',
      cycle: false,
      fileId: null as unknown as number,
      format: VideoFormat.Center,
      posterId: null as unknown as number,
      type: VideoBlockType.Iframe,
    };
  },
  widget(): BlockTypeContentMap[BlockType.Widget] {
    return {
      format: WidgetFormat.Center,
      html: '',
      text: '',
    };
  },
};
