import { BlockContentIntroCompiled, BlockType, PostType } from 'pn-backend';
import { inc } from 'ramda';
import React, { FC } from 'react';

import { Grid, Text } from '~atoms';
import { longreadBlockMap } from '~blockMaps';
import { ShareButton, SMI2 } from '~components';
import { useMobile } from '~hooks';
import {
  AdvBlockArticleBillboard,
  AdvBlockArticleBillboardMob,
  AdvBlockArticleBillboardTopMob,
  AdvBlockArticleInline,
  AdvBlockArticleInlineMob,
} from '~mocks';
import { ReadMore } from '~readMore';
import { PostProps } from '~types';
import { blockTypeEquals, getPostSummary, isDefined, renderPostBlock } from '~utils';

import { LongCoverBlock } from './LongCoverBlock';

export const Longread: FC<PostProps<PostType.Longread>> = ({ nextPosts: readMorePosts, post }) => {
  const {
    authors,
    compiled: { blocks },
  } = post;
  const [author] = authors;
  const { isAuthorVisible } = getPostSummary(post);

  const coverBlockIndex = inc(blocks.findIndex(blockTypeEquals(BlockType.Cover)));

  const coverBlocks = blocks.slice(0, coverBlockIndex);
  const restBlocks = blocks.slice(coverBlockIndex);

  const isMobile = useMobile();

  // Для пустой вводки - другая логика отображения рекламы - после первого блока текста
  const isFirstBlockEmpty =
    restBlocks[0].type === BlockType.Intro &&
    !(restBlocks[0].compiled as BlockContentIntroCompiled)?.html;

  return (
    <Grid $marginBottom="60px" $sm={{ $marginBottom: '40px' }}>
      <LongCoverBlock blocks={coverBlocks} />

      <Grid>
        {typeof isMobile !== 'undefined' && isMobile && <AdvBlockArticleBillboardTopMob />}
      </Grid>

      <Grid $position="relative">
        {restBlocks.map((block, i) => {
          let deskAds = !isMobile && (i + 2) % 3 === 0;

          if (isFirstBlockEmpty) {
            deskAds = !isMobile && i !== 0 && (i + 1) % 3 === 0;
          }

          const mobAds = isMobile && i !== 0 && i % 3 === 0;
          const withAdvBlock = deskAds || mobAds;

          if (withAdvBlock) {
            return (
              <>
                {isMobile ? (
                  <AdvBlockArticleInlineMob index={i} />
                ) : (
                  <AdvBlockArticleInline index={i} />
                )}
                {renderPostBlock(longreadBlockMap)(block, i)}
              </>
            );
          }
          return renderPostBlock(longreadBlockMap)(block, i);
        })}
      </Grid>
      <Grid
        $maxWidth="750px"
        $width="100%"
        $margin="70px auto 0"
        $display="flex"
        $justifyContent="space-between"
        $alignItems="center"
        $sm={{ $margin: '50px auto 0', $padding: '0 15px' }}
      >
        <Grid>
          {isDefined(author) && isAuthorVisible && (
            <Text
              $weight={600}
              $size={20}
              $lineHeight={22}
              $sm={{
                $lineHeight: 20,
                $size: 18,
              }}
            >
              {author.name}
            </Text>
          )}
        </Grid>

        <ShareButton />
      </Grid>
      <Grid $margin="30px auto 0">
        {typeof isMobile !== 'undefined' && !isMobile && <AdvBlockArticleBillboard />}
        {typeof isMobile !== 'undefined' && isMobile && <AdvBlockArticleBillboardMob />}
      </Grid>
      <ReadMore posts={readMorePosts} />
      <Grid
        $background="#F0F0F0"
        $width="100vw"
        $position="relative"
        $left="50%"
        $right="50%"
        $margin="60px -50vw 0"
        $sm={{
          $margin: '60px -50vw 40px',
        }}
      >
        <SMI2 />
      </Grid>
    </Grid>
  );
};
