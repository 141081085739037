import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, useContext } from 'react';

import { Close } from '~assets';
import { Grid, RoundIcon } from '~atoms';
import { usePost } from '~hooks';
import { StateContext } from '~providers';

import styles from './styles.module.scss';
import { getSharesUrl, sharesIcons } from './utils';

type Props = {
  shareInfo?: { imageURL: string; result: string };
  toggleShares: () => void;
};

export const Shares: FC<Props> = ({ shareInfo, toggleShares }) => {
  const {
    state: { settings },
  } = useContext(StateContext);

  const { post } = usePost();

  const { socialNetworkShare } = settings;

  const getIconByType = (type: string) => {
    const social = sharesIcons.find((socialObj) => socialObj.type === type);
    return social?.icon;
  };

  return (
    <Grid
      $gridAutoFlow="column"
      $gap="5px"
      $gridAutoColumns="min-content"
      $alignItems="center"
      $marginBottom="-2px"
      $lg={{ $marginBottom: '0' }}
    >
      {socialNetworkShare.map(({ type, use }, index) => {
        if (!use) return null;

        const Icon = getIconByType(type);
        const url = getSharesUrl(type, post, shareInfo);

        if (!Icon || !url) return null;

        return (
          <Link href={url} key={index}>
            <RoundIcon $color={`social-${type}`} $inverse $width="40px" $height="40px">
              <Icon className={classNames(styles[type])} />
            </RoundIcon>
          </Link>
        );
      })}
      <Grid
        onClick={toggleShares}
        $width="40px"
        $height="40px"
        $alignItems="center"
        $justifyContent="center"
      >
        <Grid $width="27px" $height="27px" $cursor="pointer">
          <Close />
        </Grid>
      </Grid>
    </Grid>
  );
};
