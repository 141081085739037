// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useLayoutEffect } from 'react';

import { Grid } from '~atoms';
import { throttle } from '~utils';

export const AdvBlock2: FC = () => {
  useLayoutEffect(() => {
    window.yaContextCb?.push(() => {
      window.Ya?.adfoxCode.create({
        containerId: 'adfox_174075260979885071',
        ownerId: 1479532,
        params: {
          p2: 'iluu',
          pp: 'h',
          ps: 'gqer',
        },
      });
    });
  }, []);

  useLayoutEffect(() => {
    const container = document.getElementById('container');
    const scrollable = document.getElementById('scrollable');

    const onScroll = () => {
      const containerRect = container.getBoundingClientRect();
      const scrollableRect = scrollable.getBoundingClientRect();
      const containerTop = containerRect.top;
      const containerBottom = containerRect.bottom;
      const scrollableBottom = scrollableRect.bottom;

      const isScrollableReachedBottom = containerBottom - scrollableBottom < 0;
      const isContainerReachedTop = containerTop < 0;
      const maxScroll = containerRect.height - scrollableRect.height;

      const getTranslate = () => {
        if (containerTop > 0) return 0;
        if (Math.abs(containerTop) > maxScroll) return maxScroll;
        return Math.abs(containerTop);
      };

      const windowHeight = window.scrollY;

      const isOutOfContainer = maxScroll > windowHeight;

      if ((isContainerReachedTop && !isScrollableReachedBottom) || isOutOfContainer) {
        scrollable.style.transform = `translateY(${getTranslate()}px)`;
      }
    };

    window.addEventListener('scroll', () => {
      onScroll();
    });
  }, []);

  // yandex_pn.glavred
  // Площадка: Postnews / * / *
  // Расположение: середина страницы
  // Тип баннера: Сайдбар - 300х600

  return (
    <Grid
      $width="300px"
      $height="600px"
      $position="sticky"
      $top={0}
      $paddingTop="30px"
      id="scrollable"
    >
      <div id="adfox_174075260979885071" />
    </Grid>
  );
};
