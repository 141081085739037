import Link from 'next/link';
import { BlockType } from 'pn-backend';
import { equals } from 'ramda';
import React, { FC, useContext, useMemo } from 'react';

import { Grid, Image, Text } from '~atoms';
import { NextButton, ShareButton, Tooltips } from '~components';
import { SectionAlias } from '~constants';
import { TestContext } from '~providers';
import { BlockProps } from '~types';
import { getSectionLink } from '~utils';

export const TestResultsBlock: FC<BlockProps<BlockType.TestResults>> = ({
  compiled: { average, maximum, minimum },
}) => {
  const {
    state: { questionAnswers, totalQuestions },
  } = useContext(TestContext);

  const correctAnswers = Object.values(questionAnswers).filter(equals(true)).length;

  const isAverage = correctAnswers >= average.valuesFrom && correctAnswers <= average.valuesTo;
  const isMaximum = correctAnswers >= maximum.valuesFrom && correctAnswers <= maximum.valuesTo;
  const isMinimum = correctAnswers >= minimum.valuesFrom && correctAnswers <= minimum.valuesTo;

  const result = useMemo(() => {
    switch (true) {
      case isMinimum:
        return minimum;
      case isMaximum:
        return maximum;
      default:
        return average;
    }
  }, [average, isMaximum, isMinimum, maximum, minimum]);

  if (!result) return null;

  const { caption, imageURL, text, title } = result;

  const shareTitle = title.trim() ? title.replace(/<[^>]*>/g, '') : '';
  const shareText = text.trim() ? text.replace(/<[^>]*>/g, '') : '';

  const shareInfo = {
    imageURL,
    result: `${shareTitle} Ваш результат — ${correctAnswers}/${totalQuestions} ${shareText}`,
  };

  return (
    <Grid $gap="30px" $sm={{ $gap: '20px' }}>
      {imageURL ? (
        <Grid
          $position="relative"
          $height="400px"
          $imageObjectFit="cover"
          $sm={{ $height: '186px', $margin: '0 15px' }}
        >
          <Image src={imageURL} alt={caption} />
          <Grid
            $margin="10px auto 0"
            $width="auto"
            $position="absolute"
            $bottom="5px"
            $right="10px"
            $left="10px"
          >
            <Tooltips html={caption} />
            <Text $weight={400} $size={12} $lineHeight={14} $color="white" $textAlign="end">
              <span dangerouslySetInnerHTML={{ __html: caption }} />
            </Text>
          </Grid>
        </Grid>
      ) : null}

      <Grid $background="white" $sm={{ $margin: '0 15px' }}>
        <Grid
          $padding="30px"
          $gap="30px"
          $lg={{ $maxWidth: '100%' }}
          $sm={{ $padding: '30px 15px 0' }}
        >
          <span>
            <Tooltips html={title} />
            <Text
              $weight={500}
              $size={24}
              $lineHeight={36}
              $textWithLink
              $sm={{ $lineHeight: 22, $size: 20 }}
            >
              <span dangerouslySetInnerHTML={{ __html: title }} />
              <span> Ваш результат — </span>
              <span>{`${correctAnswers}/${totalQuestions}`}</span>
            </Text>
            <Tooltips html={text} />
            <Text
              $weight={400}
              $size={20}
              $lineHeight={26}
              $textWithLink
              $sm={{ $lineHeight: 23, $size: 18 }}
            >
              <span dangerouslySetInnerHTML={{ __html: text }} />
            </Text>
          </span>
          <Grid $lg={{ $maxWidth: '100%' }} $sm={{ $padding: '10px 0 40px' }}>
            <ShareButton shareInfo={shareInfo} />
          </Grid>
        </Grid>

        <Link href={getSectionLink(SectionAlias.Tests)} passHref legacyBehavior>
          <NextButton>другие тесты</NextButton>
        </Link>
      </Grid>
    </Grid>
  );
};
